import React from 'react'
import logo from '../assets/logo.png';

const Nav = ({ onAboutClick }) =>{
  return(
    <nav className="nav">
      <div className="nav-content">
        <a href="/">
            <img src={logo} className="logo" alt="logo"/>
            <p>PomodoroGo</p>
        </a>
        <a>
          <button className="nav-button" onClick={onAboutClick}>About</button>
        </a>
      </div>
      <hr/>
    </nav>
  )
}

export default Nav