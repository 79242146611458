import React from 'react';

import TimerTypeSelection from '../components/TimerTypeSelection'
import RestIdeas from '../components/RestIdeas'
import StartPauseButton from '../components/StartPauseButton'
import Timer from '../components/Timer'

const TimerBody = (props) => {
    const {
        timerType,
        onTimerTypeClick,
        min,
        secDis,
        timer,
        timerIsRunning, 
        restIdeas,
        cycles
    } = props
    return (
        <div className="timer-body">
            <div className="timer-container">
                <TimerTypeSelection
                    timerType={timerType}
                    onTimerTypeClick={onTimerTypeClick}
                />
                <h3>Pomo Count: {cycles}</h3>
                <Timer min={min} secDis={secDis} />
                <StartPauseButton
                    timerType={timerType}
                    timer={timer}
                    timerIsRunning={timerIsRunning}
                 />
            </div>
            <div className={timerType}>
                {timerType === "short rest" || timerType === "long rest"
                    ? <RestIdeas
                        restIdeas={restIdeas}
                        timerType={timerType}
                    />
                    : null}
            </div>
        </div>
    )
}

export default TimerBody