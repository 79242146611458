import React from 'react'

const StartPauseButton = ({timerType, timer, timerIsRunning}) =>{
  const timerAction = timerIsRunning ? 'Pause' : 'Start'
  return(
    <button
        className = {timerType==="focus" ? "timer-button-focus" : timerType==="short rest" ? "timer-button-short-rest" : "timer-button-long-rest"}
        onClick={()=>timer()}
    >
        {timerAction}
    </button>
  )
}

export default StartPauseButton