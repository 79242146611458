import React from 'react'

class RestIdeas extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            restIdeas: this.props.restIdeas,
            index: 0,
            timerType: this.props.timerType
        }
    }
    
    componentDidMount(){
        console.log('componentdidmount')
        this.timer = setInterval(()=>{
            if(this.state.index == this.props.restIdeas.length){
                clearInterval(this.timer)
                return
            }
            this.setState(prevState => {
                return {
                    index: prevState.index +1, 
                }
            })
        }, 500)
    }
    componentDidUpdate(prevProps){
        if(prevProps.timerType!==this.props.timerType){
            this.setState((prevState)=>({
                ...prevState,
                restIdeas: this.props.restIdeas,
                timerType: this.props.timerType
            }))
        }
    }

    render(){
        const { restIdeas, index, timerType } = this.state
        const restIdeasList = restIdeas.slice(0, index)
        const minutes = timerType==="short rest" ? '5' : '15'
        return (
            <div className="rest-container">
                <div className="rest-titles">
                    <h2 className="rest-title">Time to rest</h2>
                    <h3 className="rest-subtitle">In {minutes} minutes you could...</h3>
                </div>
                <hr className="hr" />
                <div className="rest-ideas">
                {
                    restIdeasList.map((idea)=>{
                        return <div key={idea}><h4>{idea}</h4></div>
                    })
                }
                </div>
            </div>
        )
    }
}

export default RestIdeas