import React from 'react'

const About = ({ aboutClass }) =>{
    return (
    <div id={aboutClass}>
    <div className="description-body">
      <h2>About Pomodoro Technique</h2>
      <hr className={aboutClass}/>
      <div className="description">
        <h3>Created by</h3>
        <p>
          <a
            href="https://francescocirillo.com/pages/pomodoro-technique" 
            target="_blank"
          >
            Francesco Cirillo
          </a> in the late 1980s
        </p>
        <h3>Concept</h3><p>Focus on the process and not the product to help avoid procrastination and to get things done</p>
        <h3>High level</h3>
          <p>
          <a
            href="https://en.wikipedia.org/wiki/Pomodoro_Technique" 
            target="_blank"
          >
            Pomodoro Technique
          </a> is a timeboxing method of time management</p>
        <h3>Specifically</h3><p>The timer helps you break down work into 25 minute intervals of focus followed by timeboxed periods of rest</p>
      </div>
      <div className="steps">
        <h3>Pomodoro in 6 Steps</h3>
        <ol>
          <li>Define task to be done</li>
          <li>Set Pomodoro timer</li>
          <li>Focus on task for 25 minutes</li>
          <li>Stop working when the timer rings</li>
          <li>Take a short rest for 5 minutes</li>
          <li>Take a long rest for 15 minutes every 4 Pomodoro cycles</li>
        </ol>
      </div>
    </div>
  </div>)
}

export default About